import { DashboardAssignmentRole } from "@generated/graphql";
import { TTAttendanceModal } from "./TTAttendanceModal";
import { ISAttendanceModal } from "./ISAttendanceModal";

type Props = {
  show: boolean;
  role: DashboardAssignmentRole;
  teacherName: string;
  attendanceId: string;
  closeModal: () => void;
};

export function AttendanceModal({
  show,
  role,
  teacherName,
  attendanceId,
  closeModal,
}: Props) {
  switch (role) {
    case DashboardAssignmentRole.Tt:
      return (
        <TTAttendanceModal
          show={show}
          closeModal={closeModal}
          teacherName={teacherName}
          attendanceId={attendanceId}
        />
      );
    case DashboardAssignmentRole.Is:
      return (
        <ISAttendanceModal
          show={show}
          closeModal={closeModal}
          teacherName={teacherName}
          attendanceId={attendanceId}
        />
      );
    default:
      break;
  }
}
