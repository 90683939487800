import { gql, useMutation, useQuery } from "@apollo/client";
import {
  GetIsAttendanceQuery,
  GetIsAttendanceQueryVariables,
  EngagementInstructionalSupportAttendanceStatus,
  TakeIsAttendanceMutation,
  TakeIsAttendanceMutationVariables,
} from "@generated/graphql";
import { ErrorBox, Modal } from "components/shared";
import { DynamicTextArea } from "components/shared/Inputs/DynamicTextArea";
import { useEffect, useState } from "react";
import { AttendanceStatusInput } from "sections/EngagementsDashboard/components/AttendanceRow";
import { AttendanceStatus } from "types/global";
import { useDebounce } from "use-debounce";

const GET_IS_ATTENDANCE_QUERY = gql`
  query GetISAttendance($ids: [ID!]!) {
    engagementIsAttendanceEntries(ids: $ids) {
      id
      notes
      status
    }
  }
`;

const TAKE_IS_ATTENDANCE_MUTATION = gql`
  mutation TakeISAttendance(
    $input: EditEngagementInstructionalSupportAttendanceInput!
  ) {
    editEngagementInstructionalSupportAttendance(input: $input) {
      id
    }
  }
`;

type Props = {
  show: boolean;
  teacherName: string;
  attendanceId: string;
  closeModal: () => void;
};

export function ISAttendanceModal({
  show,
  teacherName,
  attendanceId,
  closeModal,
}: Props) {
  const [stateChanged, setStateChanged] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [substituteId, setSubstituteId] = useState<string | null>(null);
  const { Unknown, Absent, Present } =
    EngagementInstructionalSupportAttendanceStatus;
  const [attendanceNotes, setAttendanceNotes] = useState<string | null>("");
  const [debouncedNotes, { isPending }] = useDebounce(attendanceNotes, 1000);
  const debouncePending = isPending();

  const [attendanceStatus, setAttendanceStatus] =
    useState<AttendanceStatus>(Unknown);

  const [takeISAttendance, { loading: takeISAttendanceLoading }] = useMutation<
    TakeIsAttendanceMutation,
    TakeIsAttendanceMutationVariables
  >(TAKE_IS_ATTENDANCE_MUTATION);

  useQuery<GetIsAttendanceQuery, GetIsAttendanceQueryVariables>(
    GET_IS_ATTENDANCE_QUERY,
    {
      variables: { ids: [String(attendanceId)] },
      onCompleted: (data) => {
        const { notes, status } = data.engagementIsAttendanceEntries[0];

        setAttendanceNotes(notes || null);
        setAttendanceStatus(status);
        setStateChanged(false);
        setSubstituteId(substituteId || null);
      },
      onError: (error) => {
        console.error(error);
        setErrorMsg("Error loading attendance. Contact support.");
      },
      skip: !attendanceId,
    }
  );

  useEffect(() => {
    if (!stateChanged) return;
    if (!attendanceId) return;

    takeISAttendance({
      variables: {
        input: {
          notes: debouncedNotes,
          id: String(attendanceId),
          status:
            attendanceStatus as EngagementInstructionalSupportAttendanceStatus,
        },
      },
      refetchQueries: ["AttendanceModalFetchAttendance"],
    });
  }, [
    debouncedNotes,
    attendanceStatus,
    substituteId,
    stateChanged,
    attendanceId,
    takeISAttendance,
  ]);

  return (
    <Modal show={show} onClose={closeModal} title={teacherName} width="medium">
      <div className="w-full max-w-lg min-w-xs gap-2 flex flex-col items-stretch">
        <DynamicTextArea
          rows={4}
          value={attendanceNotes}
          onChange={(value) => {
            setAttendanceNotes(value);
            setStateChanged(true);
          }}
          placeholder="Add your note..."
          debouncePending={debouncePending}
          mutationLoading={takeISAttendanceLoading}
        />
        <div className="self-end flex-center flex gap-6">
          <AttendanceStatusInput
            attendanceRowId={attendanceId}
            status={attendanceStatus}
            onChange={(status) => {
              setAttendanceStatus(status as AttendanceStatus);
              setStateChanged(true);
            }}
            attendanceStatusOptions={[Present, Absent]}
          />
        </div>

        <ErrorBox msg={errorMsg} />

        <Modal.Buttons>
          <Modal.Button
            type="cancel"
            onClick={closeModal}
            disabled={takeISAttendanceLoading}
          >
            Close
          </Modal.Button>
        </Modal.Buttons>
      </div>
    </Modal>
  );
}
